import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-504cf63c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "valign section-header" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]
const _hoisted_5 = ["src"]
const _hoisted_6 = ["src"]
const _hoisted_7 = { key: 0 }
const _hoisted_8 = ["src"]
const _hoisted_9 = ["src"]
const _hoisted_10 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_swiper = _resolveComponent("swiper")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_dashboard_pane = _resolveComponent("dashboard-pane")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($props.listHeader)
      ? (_openBlock(), _createBlock(_component_ion_text, {
          key: 0,
          color: "primary"
        }, {
          default: _withCtx(() => [
            _createElementVNode("h3", _hoisted_1, [
              _createVNode(_component_ion_icon, {
                class: "icon-28",
                icon: $setup.image
              }, null, 8, ["icon"]),
              _createTextVNode("  " + _toDisplayString($props.listHeader), 1)
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    ($props.useSlotAsSwiperSlides)
      ? (_openBlock(), _createBlock(_component_swiper, {
          key: 1,
          modules: $setup.modules,
          "slides-per-view": 1.1,
          breakpoints: {
      768: { slidesPerView: 2.1 },
      1366: { slidesPerView: $props.maxSlidesPerView ? Math.min($props.maxSlidesPerView, 3.1) : 3.1 },
    },
          spaceBetween: 0,
          scrollbar: true,
          navigation: true,
          freeMode: false
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ]),
          _: 3
        }, 8, ["modules", "breakpoints"]))
      : ($props.workLocations)
        ? (_openBlock(), _createBlock(_component_swiper, {
            key: 2,
            pagination: true,
            navigation: true,
            modules: $setup.modules,
            "slides-per-view": 1.5,
            breakpoints: {
      768: { slidesPerView: 2.5 },
      1024: { slidesPerView: Math.min($props.workLocations.length, 3.5) },
      1366: { slidesPerView: Math.min($props.workLocations.length, 5.1) },
      1600: { slidesPerView: Math.min($props.workLocations.length, 6.1) },
    },
            spaceBetween: 10,
            autoplay: { delay: 3000 },
            speed: 1000,
            scrollbar: true
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.workLocations, (wl) => {
                return (_openBlock(), _createBlock(_component_swiper_slide, {
                  key: wl.id
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_button, {
                      size: "small",
                      fill: "solid",
                      class: "top-left",
                      onClick: ($event: any) => ($setup.onClickOutputMapPhoto(wl))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString($setup.t('details')), 1)
                      ]),
                      _: 2
                    }, 1032, ["onClick"]),
                    _createElementVNode("img", {
                      src: wl.outputMapPhotoLink
                    }, null, 8, _hoisted_2)
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          }, 8, ["modules", "breakpoints"]))
        : ($props.imageLinks)
          ? (_openBlock(), _createBlock(_component_swiper, {
              key: 3,
              modules: $setup.modules,
              "slides-per-view": $setup.ops.slidesPerView,
              breakpoints: $setup.ops.breakpoints,
              spaceBetween: 10
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.imageLinks, (link) => {
                  return (_openBlock(), _createBlock(_component_swiper_slide, {
                    key: link,
                    onClick: ($event: any) => ($setup.openImageModal(link, $props.defaultCaption))
                  }, {
                    default: _withCtx(() => [
                      (!$props.hideEyeIcon)
                        ? (_openBlock(), _createBlock(_component_ion_icon, {
                            key: 0,
                            class: "eye-icon",
                            icon: $setup.eye
                          }, null, 8, ["icon"]))
                        : _createCommentVNode("", true),
                      ($props.size == 'small')
                        ? (_openBlock(), _createElementBlock("img", {
                            key: 1,
                            class: "slide-img-small",
                            src: $setup.addResizeUrlParams(link, 200)
                          }, null, 8, _hoisted_3))
                        : (_openBlock(), _createElementBlock("img", {
                            key: 2,
                            class: "slide-img",
                            src: $setup.addResizeUrlParams(link, 300)
                          }, null, 8, _hoisted_4))
                    ]),
                    _: 2
                  }, 1032, ["onClick"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["modules", "slides-per-view", "breakpoints"]))
          : ($props.photos)
            ? (_openBlock(), _createBlock(_component_swiper, {
                key: 4,
                modules: $setup.modules,
                "slides-per-view": $setup.ops.slidesPerView,
                breakpoints: $setup.ops.breakpoints,
                spaceBetween: 10
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.photos, (photo) => {
                    return (_openBlock(), _createBlock(_component_swiper_slide, {
                      key: photo,
                      onClick: ($event: any) => ($setup.openImageModal(photo.link, photo.description))
                    }, {
                      default: _withCtx(() => [
                        (!$props.hideEyeIcon)
                          ? (_openBlock(), _createBlock(_component_ion_icon, {
                              key: 0,
                              class: "eye-icon",
                              icon: $setup.eye
                            }, null, 8, ["icon"]))
                          : _createCommentVNode("", true),
                        ($props.size == 'small')
                          ? (_openBlock(), _createElementBlock("img", {
                              key: 1,
                              class: "slide-img-small",
                              src: $setup.addResizeUrlParams(photo.link, 200)
                            }, null, 8, _hoisted_5))
                          : (_openBlock(), _createElementBlock("img", {
                              key: 2,
                              class: "slide-img",
                              src: $setup.addResizeUrlParams(photo.link, 300)
                            }, null, 8, _hoisted_6))
                      ]),
                      _: 2
                    }, 1032, ["onClick"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["modules", "slides-per-view", "breakpoints"]))
            : ($props.qualificationImages)
              ? (_openBlock(), _createBlock(_component_swiper, {
                  key: 5,
                  navigation: true,
                  modules: $setup.modules,
                  "slides-per-view": 1,
                  spaceBetween: 10
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.qualificationImages, (photo, i) => {
                      return (_openBlock(), _createBlock(_component_swiper_slide, { key: i }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", null, [
                            (photo.caption)
                              ? (_openBlock(), _createElementBlock("p", _hoisted_7, [
                                  _createElementVNode("b", null, _toDisplayString(photo.caption), 1)
                                ]))
                              : _createCommentVNode("", true),
                            (photo.image)
                              ? (_openBlock(), _createElementBlock("img", {
                                  key: 1,
                                  src: photo.imageLink
                                }, null, 8, _hoisted_8))
                              : _createCommentVNode("", true)
                          ])
                        ]),
                        _: 2
                      }, 1024))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modules"]))
              : _createCommentVNode("", true),
    ($props.deviceAlerts)
      ? (_openBlock(), _createBlock(_component_swiper, {
          key: 6,
          pagination: true,
          navigation: true,
          modules: $setup.modules,
          "slides-per-view": 3.1,
          breakpoints: {
      1024: { slidesPerView: Math.min($props.deviceAlerts.length, 4.1) },
      1366: { slidesPerView: Math.min($props.deviceAlerts.length, 5.1) },
      1600: { slidesPerView: Math.min($props.deviceAlerts.length, 6.1) },
    },
          spaceBetween: 10,
          autoplay: { delay: 3000 },
          loop: true,
          speed: 1000,
          scrollbar: true
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.deviceAlerts, (alert) => {
              return (_openBlock(), _createBlock(_component_swiper_slide, {
                key: alert.id,
                onClick: ($event: any) => ($setup.openImageModal($setup.getProxyImgLink(alert.imageLink), `${$setup.getRelativeDate(alert.startAt)} - ${alert.event}`))
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", null, [
                    _createElementVNode("img", {
                      src: $setup.getProxyImgLink(alert.imageLink)
                    }, null, 8, _hoisted_9),
                    _createVNode(_component_ion_badge, {
                      color: "danger",
                      style: {"margin-top":"-12px","margin-bottom":"4px","padding":"4px 8px","font-size":"11px"}
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString($setup.getRelativeDate(alert.startAt)), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ])
                ]),
                _: 2
              }, 1032, ["onClick"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["modules", "breakpoints"]))
      : _createCommentVNode("", true),
    ($props.camDevices)
      ? (_openBlock(), _createBlock(_component_swiper, {
          key: 7,
          pagination: true,
          navigation: true,
          modules: $setup.modules,
          "slides-per-view": 1.1,
          breakpoints: {
      1024: { slidesPerView: Math.min($props.camDevices.length, 2.1) },
      1600: { slidesPerView: Math.min($props.camDevices.length, 3.1) },
    },
          spaceBetween: 10,
          autoplay: { delay: 3000 },
          loop: true,
          speed: 1000,
          scrollbar: true
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.camDevices, (cam) => {
              return (_openBlock(), _createBlock(_component_swiper_slide, {
                key: cam.id
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_dashboard_pane, {
                    title: cam.floor || $setup.tStr('實時工地情況', 'Surveillance'),
                    icon: $setup.videocamOutline
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("video", {
                        style: {"width":"100%"},
                        id: `video-stream-${cam.id}`,
                        class: "video-js",
                        preload: "auto",
                        controls: "",
                        autoplay: "true",
                        muted: "muted"
                      }, null, 8, _hoisted_10)
                    ]),
                    _: 2
                  }, 1032, ["title", "icon"])
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        }, 8, ["modules", "breakpoints"]))
      : _createCommentVNode("", true)
  ], 64))
}